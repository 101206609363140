import { CSSProperties, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { pages, onKeyDown } from '../index';
import './Header.css';

interface HeaderProps {
  height: string;
  sidesHeight: string;
  isLandingPage: boolean;
}


Header.defaultProps = {
  isLandingPage: false
}

function Header(props: HeaderProps) {
  const styles: CSSProperties = {
    height: props.height,
  }
  const clipPathStyles: CSSProperties = {
    // clipPath: `polygon(50% 100%, 0 ${props.sidesHeight}, 0 0, 100% 0, 100% ${props.sidesHeight})`,
    height: 'inherit',
  }
  const pathname: string = useLocation().pathname;
  const [socialsMenuExpanded, setSocialsMenuExpanded] = useState(false);

  const toggleSocialsMenu = (force?: boolean) => {
    setSocialsMenuExpanded(force === undefined ? !socialsMenuExpanded : force);
    return
  };

  const shouldBlur = (e: React.FocusEvent<HTMLElement, Element>) => {
    if (e.relatedTarget) {
      const shouldBlur = !e.currentTarget.contains(e.relatedTarget);
      setSocialsMenuExpanded(!shouldBlur);
      e.stopPropagation();
    }
  }

  return (
    <div className={`Header ${ props.isLandingPage ? 'landing-page' : ''}`} style={styles} onBlur={e => setSocialsMenuExpanded(false)}>
      {!props.isLandingPage && (<div className='buttons-container'>
        <Link to='/' tabIndex={-1}><button id='home' type='button' className='round-button' tabIndex={1}></button></Link>
        <div className='socials' onBlur={e => shouldBlur(e)}>
          <button id='socials-button' type='button' className='round-button' onClick={e => toggleSocialsMenu()} tabIndex={4}></button>
          <div className={`socials-menu ${socialsMenuExpanded ? 'expanded' : 'collapsed'}`}>
            <a href='mailto:oaflynn98@gmail.com' target='_blank' rel='noreferrer' tabIndex={-1}><button id='email-circle' className='round-button' tabIndex={socialsMenuExpanded ? 0: -1}></button></a>
            <a href='https://www.linkedin.com/in/olivia-aiden-flynn/' target='_blank' rel='noreferrer' tabIndex={-1}><button id='linkedin-circle' className='round-button' tabIndex={socialsMenuExpanded ? 0: -1}></button></a>
            <a href='https://github.com/flynnolivia' target='_blank' rel='noreferrer' tabIndex={-1}><button id='github-circle' className='round-button' tabIndex={socialsMenuExpanded ? 0: -1}></button></a>
            <a href='https://twitter.com/oaflynn_dev' target='_blank' rel='noreferrer' tabIndex={-1}><button id='twitter-circle' className='round-button' tabIndex={socialsMenuExpanded ? 0: -1}></button></a>
          </div>
        </div>
      </div>)}
      <div className="header-wrapper" style={clipPathStyles}>
        {props.isLandingPage ? (
          <div className='landing-wrapper'>
            <div className='row-one'>
                <img src={require('../Images/headshot.png')} alt="Olivia Flynn's headshot"></img>
                <div className='basic-info'>
                  <h1>OLIVIA A. FLYNN</h1>
                  <ul>
                    <a href='mailto:oaflynn98@gmail.com' target='_blank' rel='noreferrer'><li id='email' onKeyDown={e => onKeyDown(e)} tabIndex={0}>oaflynn98@gmail.com</li></a>
                    <a href='https://www.linkedin.com/in/olivia-aiden-flynn/' target='_blank' rel='noreferrer'><li id='linkedin' onKeyDown={e => onKeyDown(e)} tabIndex={0}>olivia-aiden-flynn</li></a>
                    <a href='https://github.com/flynnolivia' target='_blank' rel='noreferrer'><li id='github' onKeyDown={e => onKeyDown(e)} tabIndex={0}>flynnolivia</li></a>
                    <a href='https://twitter.com/oaflynn_dev' target='_blank' rel='noreferrer'><li id='twitter' onKeyDown={e => onKeyDown(e)} tabIndex={0}>@oaflynn_dev</li></a>
                  </ul>
                </div>
            </div>
            <div className='row-two'>
              <div className='landing-nav'>
                {pages.map(page => {
                  return (
                    <Link to={`/${page}`} key={page} tabIndex={-1}>
                      <div className='button-background' onKeyDown={e => onKeyDown(e)} tabIndex={0}>
                        <button type='button' tabIndex={-1}>{page.toUpperCase()}</button>
                      </div>
                    </Link>);
                })}
              </div>
            </div>
          </div>
        ) :
        (<div className='Header' style={styles}>
          <div className='wrapper'>
            <div className='link-nav'>
            {pages
              .filter(page => '/'+page !== pathname)
              .map((page, i) => {
                return (
                  <span className='nav-link'>
                  <Link to={`/${page}`} key={page}>
                    <span className='link-text' onKeyDown={e => onKeyDown(e)} tabIndex={i+2}>
                        {page}
                    </span>
                  </Link>
                  {i === pages.length-2 ? '' : ' | '}
                  </span>
                )})}
            </div>
          </div>
        </div>)
        }
        {props.isLandingPage && <video poster='../Images/arial-trees-still.png' id='video-background' muted loop autoPlay disablePictureInPicture playsInline><source src='arial-trees.mp4' type='video/mp4' /></video>}
      </div>
    </div>
  );
}

export default Header;
